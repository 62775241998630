import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-scroll'
import { Link as LinkRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import Logo from '../Assets/img/logomenu.gif'
import i18n from '../i18n'

import logo0 from '../Assets/img/greenbook-k.png';
import './ResponsiveMenu.css'


const ResponsiveMenu = props => {
    const { toggleNavbar, changeLanguage } = props
    const { t } = useTranslation()

    const handleCultivation = () => {
        window.open('https://www.eventbrite.com/e/997322427227?aff=website', '_blank');
    }

    const handleCompliance = () => {
        window.open('https://www.eventbrite.com/e/987227713667?aff=website', '_blank');
    }

    const handleBudtenders = () => {
        window.open('https://www.eventbrite.com/e/987305706947?aff=website', '_blank');
    }

    const handleUnderstandingCannabis = () => {
        window.open('https://www.eventbrite.com/e/997210542577?aff=website', '_blank');
    }

    // const handleMedicalProfessionals = () => {
    //     window.open('https://greenbookacademy.classonlive.com/curso/Physician-Education-1667730046081', '_blank');
    // }

    // const handleChildrenCannabis = () => {
    //     window.open('https://greenbookacademy.classonlive.com/curso-completo/Best-practices-with-childrens', '_blank');
    // }

    // const treatYourConditions = () => {
    //     window.open('https://greenbookacademy.classonlive.com/curso-completo/Children-Premium-Course', '_blank');
    // }

    return (
        <>
        <div className="responsive-menu">
            <ul id="header" style={{ margin: 0 }}>
                <li className="navbar-logo-wrapper">
                    <button aria-controls="responsive-navbar-nav" type="button" aria-label="Toggle navigation" className="navbar-toggler collapsed" onClick={toggleNavbar}  >
                        <FontAwesomeIcon icon={faBars} color="white" />
                    </button>
                    <a href="/" title="GreenBookAcademy" alt="GreenBookAcademy">
                        <img
                        className="navbar-logo lazy"
                        src={`${Logo}`}
                        title="GreenBook Academy Logo" alt="GreenBook Academy Logo">
                        </img>
                    </a>
                </li>
            </ul>
        </div>
        <div className="nav-menu" id="auxnav">
            <div className="Modal-content">
                <div className="responsive-menu">
                    <ul id="menu">
                        <div className={`language ${i18n.language === 'en' ? 'selected' : ''}`}  onClick={() => changeLanguage('en')}>
                            <span>
                                English
                            </span>
                        </div>
                        <div className={`language ${i18n.language === 'es' ? 'selected' : ''}`}  onClick={() => changeLanguage('es')}>
                            <span>
                                Español
                            </span>
                        </div>
                        <div className={`language ${i18n.language === 'po' ? 'selected' : ''}`}  onClick={() => changeLanguage('po')}>
                            <span>
                                Português
                            </span>
                        </div>
                        <div className="nav-menu-courses">
                            <Link
                                onClick={toggleNavbar}
                                activeClass="active"
                                to="careers-pointer"
                                spy={true}
                                smooth={true}
                                duration={700}
                                className="link"
                                offset={-50}
                            >
                                <img src={logo0} alt="Greenbook Academy logo" /> {t('header-6')}
                            </Link>
                            <Link
                                to="#"
                                className="sublink premium"
                                onClick={() => handleCultivation()}
                            >
                                {t('course-option-1')}
                            </Link>
                            <Link
                                to="#"
                                className="sublink premium"
                                onClick={() => handleCompliance()}
                            >
                                {t('course-option-4')}
                            </Link>
                            <Link
                                to="#"
                                onClick={() => handleBudtenders()}
                                className="sublink premium"
                            >
                                {t('course-option-5')}
                            </Link>
                            <Link
                                to="#"
                                onClick={() => handleUnderstandingCannabis()}
                                className="sublink premium"
                            >
                                {t('course-option-2')}
                            </Link>
                            {/* <Link
                                to="#"
                                onClick={() => handleMedicalProfessionals()}
                                className="sublink premium"
                            >
                                {t('course-option-6')}
                            </Link>
                            <Link
                                to="#"
                                onClick={() => treatYourConditions()}
                                className="sublink premium"
                            >
                                {t('course-option-7')}
                            </Link>
                            <Link
                                to="#"
                                onClick={() => handleChildrenCannabis()}
                                className="sublink premium"
                            >
                                {t('course-option-8')}
                            </Link>
                            <Link
                                onClick={() => handleShowModalGrants()}
                                activeClass="active"
                                to="#"
                                spy={true}
                                smooth={true}
                                duration={700}
                                className="sublink"
                                offset={-50}
                            >
                                {t('course-option-2')}
                            </Link>
                            <Link
                                onClick={() => handleShowModalGrants()}
                                activeClass="active"
                                to="#"
                                spy={true}
                                smooth={true}
                                duration={700}
                                className="sublink"
                                offset={-50}
                            >
                                {t('course-option-9')}
                            </Link>
                            <Link
                                onClick={() => handleShowModalGrants()}
                                activeClass="active"
                                to="#"
                                spy={true}
                                smooth={true}
                                duration={700}
                                className="sublink"
                                offset={-50}
                            >
                                {t('course-option-10')}
                            </Link>
                            <Link
                                onClick={toggleNavbar}
                                activeClass="active"
                                to="instructors-pointer"
                                spy={true}
                                smooth={true}
                                duration={700}
                                className="link"
                                offset={-50}
                            >
                                <img src={logo0} alt="Greenbook Academy logo" /> {t('header-5')}
                            </Link> */}
                            <Link
                                onClick={toggleNavbar}
                                activeClass="active"
                                to="about-pointer"
                                spy={true}
                                smooth={true}
                                duration={700}
                                className="link"
                                offset={-50}
                            >
                                <img src={logo0} alt="Greenbook Academy logo" /> {t('header-3')}
                            </Link>
                            {/* <LinkRouter
                                activeClass="active"
                                to="/media"
                                spy={true}
                                smooth={true}
                                duration={700}
                                className="link"
                                offset={-50}
                            >
                                <img src={logo0} alt="Greenbook Academy logo" /> {t('header-7')}
                            </LinkRouter> */}
                            <LinkRouter
                                activeClass="active"
                                to="/calendar"
                                spy={true}
                                smooth={true}
                                duration={700}
                                className="link"
                                offset={-50}
                            >
                                <img src={logo0} alt="Greenbook Academy logo" /> {t('header-8')}
                            </LinkRouter>
                            <Link
                                activeClass="active"
                                to="contactSection"
                                spy={true}
                                smooth={true}
                                duration={700}
                                className="link"
                                offset={-50}
                            >
                                <img src={logo0} alt="Greenbook Academy logo" /> {t('header-4')}
                            </Link>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
        </>
    )
}

export default ResponsiveMenu
